import React from "react";

import DefaultPage from "../components/DefaultPage";
import SEO from "../components/Seo";
import Section from "../components/Section";
import SectionHeader from "../components/SectionHeader";

const NotFoundPage = () => (
  <DefaultPage nav={false}>
    <SEO title="404: Seite konnte nicht gefunden werden" />
    <Section>
      <SectionHeader headline="404: Seite konnte nicht gefunden werden" />
      <p>Leider existiert keine Seite hinter der Url, die Du aufgerufen hast</p>
    </Section>
  </DefaultPage>
);

export default NotFoundPage;
